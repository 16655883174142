<template>
  <div>
    <v-card>
      <v-card-title v-show="currentTab !== 'GUESTLIST'">
        <v-row>
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  clearable
                  name="name"
                  label="Search By Name"
                  v-model="query.search"
                  placeholder="e.g. Active"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="8" xs="3" class="text-left text-sm-right">
            <v-row>
              <v-spacer />
              <v-col cols="4" sm="3" xs="3" v-show="currentTab === 'BOOKED'">
                <v-btn block outlined  :disabled="selected.length === 0" color="primary" style="white-space: normal;" @click="openMailContainer()">Send Email</v-btn>
              </v-col>
              <v-col cols="4" sm="4" xs="3">
                <v-btn block outlined :loading="loading.eventreminder" color="primary" @click="sendEmail()">Email Callover list</v-btn>
              </v-col>
              <v-col cols="4" sm="2" xs="3">
                <v-btn block outlined :loading="loading.export" color="primary" @click="exportExcel()">Export</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pt-2 pl-4 pr-4" v-show="showAllSelection">
        <v-col>
          <v-alert border="left" color="primary">
            All {{ totalGuests }} orders on this event are selected.
            <a class="white--text" @click="selectAllGuest()" v-show="!allGuestSelected">Select All</a>
            <a class="white--text" @click="releaseAllGuest()" v-show="allGuestSelected">Clear Selection</a>
          </v-alert>
        </v-col>
      </v-row>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="5" md="5" xl="3">
            <v-tabs v-model="query.status" grow>
              <v-tab href="#all" @click="tabChange('BOOKED')">Booked</v-tab>
              <v-tab href="#pending" @click="tabChange('REFUND')">Refunded</v-tab>
              <v-tab href="#guestlist" @click="tabChange('GUESTLIST')">Guest List</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-title>
      <div v-show="currentTab !== 'GUESTLIST'">
        <v-data-table
          v-model="selected"
          :headers="headers"
          show-select
          item-key="_id"
          :items="desserts"
          hide-default-footer
          :expanded.sync="expanded"
          show-expand
          :single-expand="true"
          @input="onItemSelected()"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount | currency }}</span>
          </template>
          <template v-slot:[`item.actualTicketPrice`]="{ item }">
            <span>{{ item.actualTicketPrice | currency }}</span>
          </template>
          <template v-slot:[`item.bookingFee`]="{ item }">
            <span>{{ item.bookingFee | currency }}</span>
          </template>
          <template v-slot:[`item.discount`]="{ item }">
            <span>{{ item.discount | currency }}</span>
          </template>
          <template v-slot:[`item.refundAmount`]="{ item }">
            <span>{{ item.refundAmount | currency }}</span>
          </template>
          <template v-slot:[`item.seatCount`]="{ item }">
            <span>{{ item.seatCount || 0 }}</span>
          </template>
          <template v-slot:[`item.refundCount`]="{ item }">
            <span>{{ item.refundCount || 0 }}</span>
          </template>
          <template v-slot:[`item.seat`]="{ item }">
            <span>{{ item.seat || item.reFundSeat }}</span>
          </template>
          <template v-slot:[`item.bookingDate`]="{ item }">
            <span>{{ item.createdAt | dateTime }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="isAbleToRefundTicket && item.ticketStatus !== 'REFUND' && item.isFreeTicket === 'No'"
              block
              color="primary"
              small
              :loading="loading.refund.includes(item._id)"
              @click="refund(item)"
              >Refund</v-btn
            >
            <v-btn
              v-if="isAbleToRefundTicket && item.ticketStatus === 'REFUND' && item.isFreeTicket === 'No'"
              block
              disabled="true"
              small
              >Refunded</v-btn
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>Tickets</v-card-title>
                <v-data-table :headers="ticketHeaders" :items="item.ticketData" hide-default-footer>
                  <template v-slot:[`item.ticketCategory`]="{ item }">
                    <span>{{ item.eventticketcategoriesObj.ticketcategories.name }}</span>
                  </template>
                  <template v-slot:[`item.boughtPrice`]="{ item }">
                    <span>{{ item.boughtPrice | currency }}</span>
                  </template>
                  <template v-slot:[`item.paidBookingFees`]="{ item }">
                    <span>{{ item.paidBookingFees | currency }}</span>
                  </template>
                  <template v-slot:[`item.discountPrice`]="{ item }">
                    <span>{{ item.discountPrice | currency }}</span>
                  </template>
                  <template v-slot:[`item.paidAmount`]="{ item }">
                    <span>{{ (item.paidBookingFees + item.paidPrice) | currency }}</span>
                  </template>
                  <template v-slot:[`item.ticketStatus`]="{ item }">
                    <span>{{ item.isRefund ? 'REFUNDED' : 'BOOKED' }}</span>
                  </template>
                </v-data-table>
              </v-card>
              <v-card v-if="item.addsons && item.addsons.length">
                <v-card-title>Add Ons</v-card-title>
                <v-data-table :headers="addHeaders" :items="item.addsons" hide-default-footer disable-pagination>
                  <template v-slot:[`item.addon`]="{ item }">
                    <span>{{ addsonNames[item.addon] }}</span>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <span>{{ item.price | currency }}</span>
                  </template>
                  <template v-slot:[`item.bookingFee`]="{ item }">
                    <span>{{ item.bookingFee | currency }}</span>
                  </template>
                  <template v-slot:[`item.discount`]="{ item }">
                    <span>{{ item.discount | currency }}</span>
                  </template>
                  <template v-slot:[`item.paidAmount`]="{ item }">
                    <span>{{ (item.paidBookingFee + item.paidPrice) | currency }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </td>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-if="totalPage"
              class="text-right text-body-2 py-2 "
              circle
              :total-visible="5"
              color="primary"
              v-model="query.page"
              :length="totalPage"
            ></v-pagination>
          </template>
        </v-data-table>
      </div>
      <div v-show="currentTab === 'GUESTLIST'">
        <v-data-table :headers="guestHeaders" item-key="index" :items="guestData" hide-default-footer> </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="dialog" width="800px" @click:outside="cancel()">
      <v-card>
        <v-card-title class="primary--text">
          Email Template
        </v-card-title>

        <v-card-text>
          <v-card-subtitle class="px-0 primary--text">Notes</v-card-subtitle>
          <p>
            This feature should only be used to pass on important event-related information to customers, and should not
            be used for marketing purposes.
          </p>
          <p>
            Event details - name, venue, date & time - will be taken from the event listing on TicketText so there is no
            need to alter these fields.
          </p>
          <p>
            Please add the message you want to send in the 'Add message here' section, but remember to delete 'Add
            message here' first!
          </p>
          <p>
            If you have any questions on this feature then please contact your TicketText rep or email us at
            <a href="mailto:info@tickettext.co.uk">info@tickettext.co.uk</a> and we will be happy to help.
          </p>
          <br />
          <quill-editor v-model="emailContent"> </quill-editor>
          <div v-if="!validated && !emailContent" class="v-text-field__details mt-1 px-3">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">Email template is required</div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn width="100" outlined dense color="primary" @click="cancel()">Cancel</v-btn>
          <v-btn width="100" :loading="loading.sendCustomisedEmail" color="primary" @click="sendEmailNotification()">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      expanded: [],
      query: {
        search: '',
        page: 1,
        event: '',
        ticketStatus: 'BOOKED',
      },
      addsonNames: {},
      ticketHeaders: [
        { text: 'Ticket Category', value: 'ticketCategory', sortable: false, width: '100px' },
        { text: 'Ticket Price', value: 'boughtPrice', sortable: false, width: '100px' },
        { text: 'Booking Fees', value: 'paidBookingFees', sortable: false, width: '100px' },
        { text: 'Discount', value: 'discountPrice', sortable: false, width: '100px' },
        { text: 'Amount Paid', value: 'paidAmount', sortable: false, width: '100px' },
        { text: 'Ticket Status', value: 'ticketStatus', sortable: false, width: '100px' },
      ],
      addHeaders: [
        { text: 'Name', value: 'addon', sortable: false, width: '50px' },
        { text: 'Addon Price', value: 'price', sortable: false, width: '100px' },
        // { text: 'Booking Fees', value: 'bookingFee', sortable: false, width: '100px' },
        // { text: 'Discount', value: 'discount', sortable: false, width: '100px' },
        { text: 'Amount Paid', value: 'paidAmount', sortable: false, width: '100px' },
        { text: 'Selected Item', value: 'selectedSize', sortable: false, width: '100px' },
      ],
      totalPage: '',
      isAbleToRefundTicket: false,
      headers: [
        // { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '50px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        { text: 'Email', value: 'email', sortable: false, width: '250px' },
        { text: 'Ticket Category', value: 'seat', sortable: false, width: '150px' },
        { text: '#Seats', value: 'seatCount', sortable: false, width: '200px' },
        { text: '#Refunded Seats', value: 'refundCount', sortable: false, width: '200px' },
        { text: 'Ticket Price', value: 'actualTicketPrice', sortable: false, width: '150px' },
        { text: 'Booking Fees', value: 'bookingFee', sortable: false, width: '150px' },
        { text: 'Discount', value: 'discount', sortable: false, width: '150px' },
        { text: 'Amount Paid', value: 'amount', sortable: false, width: '200px' },
        { text: 'Amount Refunded', value: 'refundAmount', sortable: false, width: '200px' },
        { text: 'Ticket Status', value: 'ticketStatus', sortable: false, width: '150px' },
        { text: 'Free Ticket', value: 'isFreeTicket', sortable: false, width: '150px' },
        { text: 'Booking Date', value: 'bookingDate', sortable: false, width: '200px' },
      ],
      guestHeaders: [
        { text: 'Title', value: 'title', sortable: false, width: '150px' },
        { text: 'Name', value: 'name', sortable: false, width: '100px' },
        { text: 'Email', value: 'email', sortable: false, width: '100px' },
        { text: 'Contact Number', value: 'number', sortable: false, width: '150px' },
        { text: 'Company Name', value: 'companyName', sortable: false, width: '150px' },
        { text: 'Seats', value: 'seats', sortable: false, width: '100px' },
        { text: 'Comments', value: 'comments', sortable: false, width: '200px' },
      ],
      promoterId: '',
      desserts: [],
      loading: {
        eventreminder: false,
        refund: [],
        export: false,
        sendCustomisedEmail: false,
      },
      currentTab: 'BOOKED',
      guestData: [],
      selected: [],
      dialog: false,
      totalGuests: 0,
      showAllSelection: false,
      allGuestSelected: false,
      emailContent: '',
      validated: true,
      originalEmailContent: this.getCustomizedMailContent(),
    };
  },
  created() {
    this.emailContent = this.originalEmailContent;
    Object.assign(this.query, this.$getState());
    this.query.event = this.$route.params.id;
    this.getTickets();
  },
  methods: {
    async getTickets() {
      // this.$setState(this.query);
      const data = await PromoterEventService.getTickets(this.query);
      if (data) {
        this.desserts = data.tickets;
        this.getNames(this.desserts);
        if (
          this.desserts &&
          this.desserts.length > 0 &&
          this.desserts[0].ticketData &&
          this.desserts[0].ticketData.length > 0 &&
          this.desserts[0].ticketData[0].seat
        ) {
          this.ticketHeaders.splice(1, 0, { text: 'Seat', value: 'seat', sortable: false, width: '100px' });
        }
        this.totalGuests = data.totalCount;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    getNames(items) {
      this.addsonNames = {};
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (element.addon && element.addon.length) {
          for (let aindex = 0; aindex < element.addon.length; aindex++) {
            const ele = element.addon[aindex];
            if (!this.addsonNames[ele._id]) {
              this.addsonNames[ele._id] = ele.name;
            }
          }
        }
      }
    },
    tabChange(data) {
      this.query.page = 1;
      this.currentTab = data;
      this.desserts = [];
      this.guestData = [];
      if (data === 'BOOKED') {
        this.query.ticketStatus = 'BOOKED';
        this.getTickets();
      } else if (data === 'REFUND') {
        this.query.ticketStatus = 'REFUND';
        this.getTickets();
      } else if (data === 'GUESTLIST') {
        this.getGuestList();
      }
    },
    async sendEventReminder() {
      this.loading.eventreminder = true;
      await PromoterEventService.sendEventReminder({ event: this.$route.params.id });
      this.loading.eventreminder = false;
    },
    async sendEmail() {
      this.loading.eventreminder = true;
      await PromoterEventService.sendEmailTickets(this.query);
      this.loading.eventreminder = false;
    },
    async getGuestList() {
      let params = {};
      if (this.query && this.query.event) {
        params['event'] = this.query.event;
      }
      const data = await PromoterEventService.getGuestList(params);
      if (data) {
        this.guestData = data.guests;
      }
    },
    openMailContainer() {
      this.dialog = true;
    },
    cancel() {
      this.emailContent = this.originalEmailContent;
      this.dialog = false;
      this.loading.sendCustomisedEmail = false;
    },
    onItemSelected() {
      let selectedItems = this.selected.length;
      this.allGuestSelected = false;
      if (this.selected.length === +process.env.VUE_APP_RECORD_PER_PAGE) {
        this.showAllSelection = true;
      } else {
        this.showAllSelection = false;
      }
    },
    selectAllGuest() {
      this.allGuestSelected = true;
    },
    releaseAllGuest() {
      this.allGuestSelected = false;
      this.showAllSelection = false;
      this.selected = [];
    },
    async sendEmailNotification() {
      this.loading.sendCustomisedEmail = true;
      let emailTemplate = this.emailContent;
      if (emailTemplate.trim() === '') {
        this.validated = false;
        return false;
      }
      let data = { event: this.$route.params.id };
      const userData = [];
      for (let index = 0; index < this.selected.length; index++) {
        const element = this.selected[index];
        userData.push(element.longId);
      }

      if (this.allGuestSelected) {
        data['longId'] = ['all'];
      } else {
        data['longId'] = userData;
      }
      data['content'] = this.emailContent;
      await PromoterEventService.sendEmailNotification(data);
      this.allGuestSelected = false;
      this.showAllSelection = false;
      this.selected = [];
      this.emailContent = this.originalEmailContent;
      this.dialog = false;
      this.loading.sendCustomisedEmail = false;
    },
    async exportExcel() {
      const fileName = `${this.desserts[0].title}-${moment().format('DD/MM/YYYY')}.xlsx`;
      let pageName = 'Callover List';
      this.loading.export = true;
      const exportdata = await PromoterEventService.exportCalloverList({
        event: this.$route.params.id,
        ticketStatus: this.query.ticketStatus,
        pageName,
        fileName,
      });
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        this.loading.export = false;
      }
      this.loading.export = false;
    },
  },
  watch: {
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getTickets();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getTickets();
        }
      }, 600);
    },
    'query.page': async function() {
      this.getTickets();
    },
  },
};
</script>
